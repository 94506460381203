var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-t-xs-20 p-b-xs-20" },
    [
      _vm.order && _vm.cart && _vm.receipt
        ? _c("div", { staticClass: "container-fluid" }, [
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "ak-data-table__loading-message" },
                  [
                    _c("ak-loader", {
                      staticClass: "ak-data-table__loader",
                      attrs: {
                        static: true,
                        loading: _vm.loading,
                        size: "small",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                Gegevens ophalen\n            "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.loading
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col-lg-12",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "m-b-xs-20" },
                        [
                          _c(
                            "ak-button",
                            {
                              staticStyle: { "vertical-align": "top" },
                              attrs: { size: "small" },
                              on: { click: _vm.backToOrders },
                            },
                            [
                              _c(
                                "i",
                                { staticClass: "ak-icon ak-button__icon" },
                                [_vm._v("arrow_left")]
                              ),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("Ga terug")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "ak-button",
                            {
                              staticStyle: { "vertical-align": "top" },
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.openOrderDefinitionForm(
                                    "order-send-form"
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "i",
                                { staticClass: "ak-icon ak-button__icon" },
                                [_vm._v("local_shipping")]
                              ),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("Verzenden")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "m-b-xs-20" }, [
                        _c(
                          "div",
                          {
                            staticClass: "cms-published-toggle",
                            staticStyle: { "vertical-align": "top" },
                          },
                          [
                            _c("ak-toggle", {
                              staticStyle: { padding: "0" },
                              attrs: {
                                id: "processed",
                                title: "Is verwerkt",
                                value: _vm.order.processed,
                              },
                              on: { input: _vm.toggleProcessed },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-8" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel ak-panel--no-padding m-b-xs-20",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ak-panel__header ak-panel__header--small",
                          },
                          [
                            _c("div", { staticClass: "ak-panel__title" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("Bestelling")) +
                                  "\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("strong", { staticClass: "m-l-auto" }, [
                              _vm._v(_vm._s(_vm.cart.code)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "table",
                          { staticClass: "ak-table ak-panel__table" },
                          [
                            _c("thead", [
                              _c("tr", { staticClass: "ak-table-row" }, [
                                _c("th", { staticClass: "ak-table-head" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("Artikel nummer	")) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "ak-table-head" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("Artikel")) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "ak-table-head" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("Aantal")) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "ak-table-head" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("Prijs")) +
                                      "\n                            "
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              { staticClass: "ak-table-body" },
                              [
                                _vm._l(_vm.receipt.items, function (line) {
                                  return _c(
                                    "tr",
                                    { staticClass: "ak-table-row" },
                                    [
                                      _c(
                                        "td",
                                        { staticClass: "ak-table-column" },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(line.code) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "ak-table-column" },
                                        [
                                          line.shoppable.href
                                            ? _c("a", {
                                                staticClass:
                                                  "ak-link ak-link--uppercase",
                                                attrs: {
                                                  href: line.shoppable.href,
                                                  target: "_blank",
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(line.title),
                                                },
                                              })
                                            : _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(line.title),
                                                },
                                              }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "ak-table-column" },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(line.quantity) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "ak-table-column" },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(line.amount.formatted) +
                                              "\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _vm._l(_vm.receipt.lines, function (line) {
                                  return _c(
                                    "tr",
                                    { staticClass: "ak-table-row" },
                                    [
                                      _c("td", {
                                        staticClass: "ak-table-column",
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        staticClass: "ak-table-column",
                                      }),
                                      _vm._v(" "),
                                      _c("td", {
                                        staticClass:
                                          "ak-table-column text--right",
                                        domProps: {
                                          innerHTML: _vm._s(line.title),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "ak-table-column" },
                                        [_vm._v(_vm._s(line.amount.formatted))]
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel m-b-xs-20  ak-panel--no-padding",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ak-panel__header ak-panel__header--small",
                          },
                          [
                            _c("div", { staticClass: "ak-panel__title" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("Betaling")) +
                                  "\n                        "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.order.paymentStatus === 1
                          ? _c(
                              "table",
                              { staticClass: "ak-table ak-panel__table" },
                              [
                                _c("tbody", { staticClass: "ak-table-body" }, [
                                  _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.$t("Betaling ontvangen")
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.inspector.paymentType
                                    ? _c(
                                        "tr",
                                        { staticClass: "ak-table-row" },
                                        [
                                          _c(
                                            "td",
                                            { staticClass: "ak-table-column" },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.inspector.paymentType
                                                      .title
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.order.paymentCardHolderName
                                    ? _c(
                                        "tr",
                                        { staticClass: "ak-table-row" },
                                        [
                                          _c(
                                            "td",
                                            { staticClass: "ak-table-column" },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.order
                                                      .paymentCardHolderName
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.order.getPaymentCardNumber
                                    ? _c(
                                        "tr",
                                        { staticClass: "ak-table-row" },
                                        [
                                          _c(
                                            "td",
                                            { staticClass: "ak-table-column" },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.order
                                                      .getPaymentCardNumber
                                                  ) +
                                                  "\n                                "
                                              ),
                                              _vm.order
                                                .paymentCardExpirationMonthAndYear
                                                ? _c("div", [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(
                                                          _vm.$t("Vervaldatum")
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.order
                                                            .paymentCardExpirationMonthAndYear
                                                        ) +
                                                        "\n                                "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.order.paymentMessage
                                    ? _c(
                                        "tr",
                                        { staticClass: "ak-table-row" },
                                        [
                                          _c(
                                            "td",
                                            { staticClass: "ak-table-column" },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.order.paymentMessage
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          : !_vm.order.cancelled
                          ? _c(
                              "table",
                              { staticClass: "ak-table ak-panel__table" },
                              [
                                _c("tbody", { staticClass: "ak-table-body" }, [
                                  _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.$t("Betaling openstaande")
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.order.paymentMessage
                                    ? _c(
                                        "tr",
                                        { staticClass: "ak-table-row" },
                                        [
                                          _c(
                                            "td",
                                            { staticClass: "ak-table-column" },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.order.paymentMessage
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _c(
                                          "ak-button",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.openOrderDefinitionForm(
                                                  "payment-form"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.$t("Markeren als betaald")
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.order.comments
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ak-panel ak-panel--no-padding m-b-xs-20",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ak-panel__header ak-panel__header--small",
                              },
                              [
                                _c("div", { staticClass: "ak-panel__title" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.$t("Opmerkingen")) +
                                      "\n                        "
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "ak-panel__body" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.order.comments) +
                                  "\n                    "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel ak-panel--no-padding m-b-xs-20",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ak-panel__header ak-panel__header--small",
                          },
                          [
                            _c("div", { staticClass: "ak-panel__title" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("Historiek")) +
                                  "\n                        "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "table",
                          { staticClass: "ak-table ak-panel__table" },
                          [
                            _c("thead", [
                              _c("tr", { staticClass: "ak-table-row" }, [
                                _c("th", { staticClass: "ak-table-head" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("Datum")) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("th", { staticClass: "ak-table-head" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.$t("Gebeurtenis")) +
                                      "\n                            "
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              { staticClass: "ak-table-body" },
                              [
                                _vm._l(
                                  _vm.order.orderHistory,
                                  function (history) {
                                    return _c(
                                      "tr",
                                      { staticClass: "ak-table-row" },
                                      [
                                        _c(
                                          "td",
                                          { staticClass: "ak-table-column" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("datetime")(
                                                  history.createdAt
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "ak-table-column" },
                                          [
                                            history.href
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: history.href,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(history.event) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                )
                                              : [
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        history.event
                                                      ),
                                                    },
                                                  }),
                                                ],
                                          ],
                                          2
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c("tr", { staticClass: "ak-table-row" }, [
                                  _c("td", { staticClass: "ak-table-column" }, [
                                    _vm._v(
                                      _vm._s(_vm._f("datetime")(_vm.cart.date))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "ak-table-column" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("Creatie winkelmandje"))
                                    ),
                                  ]),
                                ]),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel ak-panel--no-padding  m-b-xs-20",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ak-panel__header ak-panel__header--small",
                          },
                          [
                            _c("div", { staticClass: "ak-panel__title" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("Interne opmerkingen")) +
                                  "\n                        "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ak-panel__body ak-panel__body--small reading",
                          },
                          [
                            _vm.order.internalComments
                              ? _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.order.internalComments
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "ak-button",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.openOrderDefinitionForm(
                                      "internal-comments-form"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.$t("Opmerking toevoegen")) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel ak-panel--no-padding  m-b-xs-20",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ak-panel__header ak-panel__header--small",
                          },
                          [
                            _c("div", { staticClass: "ak-panel__title" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("Persoonlijke gegevens")) +
                                  "\n                        "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ak-panel__body ak-panel__body--small reading",
                          },
                          [
                            _c("p", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.cartDetails.firstName) +
                                    " " +
                                    _vm._s(_vm.cartDetails.surname)
                                ),
                              ]),
                              _c("br"),
                            ]),
                            _vm._v(" "),
                            _vm.cartDetails.streetName ||
                            _vm.cartDetails.streetNumber ||
                            _vm.cartDetails.boxNumber ||
                            _vm.cartDetails.postalCode ||
                            _vm.cartDetails.city ||
                            _vm.cartDetails.countryIso
                              ? _c("p", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.cartDetails.streetName) +
                                      " " +
                                      _vm._s(_vm.cartDetails.streetNumber) +
                                      "\n                            " +
                                      _vm._s(_vm.cartDetails.boxNumber)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.cartDetails.postalCode) +
                                      " " +
                                      _vm._s(_vm.cartDetails.city) +
                                      " " +
                                      _vm._s(_vm.cartDetails.countryIso) +
                                      "\n                        "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.cartDetails.phone)
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "mailto:" + _vm.cartDetails.email,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.cartDetails.email))]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel ak-panel--no-padding  m-b-xs-20",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ak-panel__header ak-panel__header--small",
                          },
                          [
                            _c("div", { staticClass: "ak-panel__title" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("Factuuradres")) +
                                  "\n                        "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ak-panel__body ak-panel__body--small reading",
                          },
                          [
                            _c("p", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.order.invoiceFirstName) +
                                    " " +
                                    _vm._s(_vm.order.invoiceSurnames)
                                ),
                              ]),
                              _c("br"),
                              _vm._v(" "),
                              _vm.order.invoiceCompanyName
                                ? _c("strong", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.order.invoiceCompanyName) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.order.invoiceVat
                                ? _c("span", [
                                    _vm._v(" " + _vm._s(_vm.order.invoiceVat)),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _vm.order.invoiceStreetAddress ||
                            _vm.order.invoiceStreetNumber ||
                            _vm.order.invoiceBoxNumber ||
                            _vm.order.invoicePostalCode ||
                            _vm.order.invoiceCity ||
                            _vm.order.invoiceCountryIso
                              ? _c("p", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.order.invoiceStreetAddress) +
                                      " " +
                                      _vm._s(_vm.order.invoiceStreetNumber) +
                                      "\n                            " +
                                      _vm._s(_vm.order.invoiceBoxNumber)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.order.invoicePostalCode) +
                                      " " +
                                      _vm._s(_vm.order.invoiceCity) +
                                      " " +
                                      _vm._s(_vm.order.invoiceCountryIso) +
                                      "\n                        "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.order.phone)
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _vm.order.invoiceEmail
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "mailto:" + _vm.order.invoiceEmail,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.order.invoiceEmail) +
                                          "\n                            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel ak-panel--no-padding m-b-xs-20",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ak-panel__header ak-panel__header--small",
                          },
                          [
                            _c("div", { staticClass: "ak-panel__title" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.$t("Leveringsgegevens")) +
                                  "\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("strong", { staticClass: "m-l-auto" }, [
                              _vm.order.shippingTypeId === 1
                                ? _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.$t("Afhalen")) +
                                        "\n                                "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.$t("Leveren")) +
                                        "\n                                "
                                    ),
                                  ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "table",
                          { staticClass: "ak-table ak-panel__table" },
                          [
                            _c("tbody", { staticClass: "ak-table-body" }, [
                              _vm.order.shippingCompanyName &&
                              !_vm.order.shippingName &&
                              !_vm.order.shippingReceiverName
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _c("strong", [
                                          _vm._v(_vm._s(_vm.$t("Bedrijf:"))),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.order.shippingCompanyName
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.order.shippingFirstName
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _c("strong", [
                                          _vm._v(_vm._s(_vm.$t("Voornaam:"))),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.order.shippingFirstName
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.order.shippingSurnames
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _c("strong", [
                                          _vm._v(_vm._s(_vm.$t("Achternaam:"))),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.order.shippingSurnames) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.order.shippingName
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _c("strong", [
                                          _vm._v(_vm._s(_vm.$t("Naam:"))),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.order.shippingName) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.order.shippingReceiverName
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(_vm.$t("Ontvanger naam:"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.order.shippingReceiverName
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.order.shippingEmail
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(_vm.$t("E-mailadres:"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "mailto:" +
                                                _vm.order.shippingEmail,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.order.shippingEmail
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.order.shippingStreetAddress ||
                              _vm.order.shippingStreetNumber ||
                              _vm.order.shippingBoxNumber ||
                              _vm.order.shippingPostalCode ||
                              _vm.order.shippingCity ||
                              _vm.order.shippingCountryIso
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _c("strong", [
                                          _vm._v(_vm._s(_vm.$t("Adres:"))),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.order.shippingStreetAddress
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.order.shippingStreetNumber
                                            ) +
                                            "\n                                " +
                                            _vm._s(
                                              _vm.order.shippingBoxNumber
                                            ) +
                                            ",\n                                " +
                                            _vm._s(
                                              _vm.order.shippingPostalCode
                                            ) +
                                            " " +
                                            _vm._s(_vm.order.shippingCity) +
                                            "\n                                " +
                                            _vm._s(
                                              _vm.order.shippingCountryIso
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.order.requestedDeliveryDate
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("Gewenste leveringsdatum:")
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm
                                                .$moment(
                                                  _vm.order
                                                    .requestedDeliveryDate
                                                )
                                                .format("DD-MM-YYYY")
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasDateShippingPlanned
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("Geplande leveringsdatum:")
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("router-view", {
        attrs: { name: "childview" },
        on: { paymentSaved: _vm.paymentSaved },
      }),
      _vm._v(" "),
      _c("ak-confirm", {
        attrs: {
          confirmTitle: _vm.$t("Ben je zeker?"),
          confirmText: _vm.$t(
            "Ben je zeker dat je deze bestelling wil annuleren?"
          ),
          confirmLabel: _vm.$t("Ja"),
          cancelLabel: _vm.$t("Nee"),
        },
        on: {
          confirm: _vm.cancelOrder,
          cancel: function ($event) {
            _vm.showConfirm = false
          },
        },
        model: {
          value: _vm.showConfirm,
          callback: function ($$v) {
            _vm.showConfirm = $$v
          },
          expression: "showConfirm",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }