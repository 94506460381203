<template>
    <div class="p-t-xs-20 p-b-xs-20">
        <div v-if="order && cart && receipt" class="container-fluid">
            <div v-if="loading" class="ak-data-table__loading-message">
                <ak-loader
                        :static="true"
                        :loading="loading"
                        size="small"
                        class="ak-data-table__loader"
                />
                <div>
                    Gegevens ophalen
                </div>
            </div>
            <div class="row" v-if="!loading">
                <div class="col-lg-12" style="display: flex;justify-content: space-between">
                    <div class="m-b-xs-20">
                        <ak-button
                                size="small"
                                @click="backToOrders"
                                style="vertical-align: top;"
                        >
                            <i class="ak-icon ak-button__icon">arrow_left</i>
                            {{ $t('Ga terug') }}
                        </ak-button>
                        <ak-button
                                size="small"
                                style="vertical-align: top;"
                                @click="openOrderDefinitionForm('order-send-form')">
                            <i class="ak-icon ak-button__icon">local_shipping</i>
                            {{ $t('Verzenden') }}
                        </ak-button>
                    </div>
                    <div class="m-b-xs-20">
                        <div class="cms-published-toggle" style="vertical-align: top;">
                            <ak-toggle
                                    style="padding:0"
                                    id="processed"
                                    title="Is verwerkt"
                                    :value="order.processed"
                                    @input="toggleProcessed"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="ak-panel ak-panel--no-padding m-b-xs-20">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Bestelling') }}
                            </div>
                            <strong class="m-l-auto">{{ cart.code }}</strong>
                        </div>
                        <table class="ak-table ak-panel__table">
                            <thead>
                            <tr class="ak-table-row">
                                <th class="ak-table-head">
                                    {{ $t('Artikel nummer	') }}
                                </th>
                                <th class="ak-table-head">
                                    {{ $t('Artikel') }}
                                </th>
                                <th class="ak-table-head">
                                    {{ $t('Aantal') }}
                                </th>
                                <th class="ak-table-head">
                                    {{ $t('Prijs') }}
                                </th>
                            </tr>
                            </thead>
                            <tbody class="ak-table-body">
                            <tr v-for="line in receipt.items" class="ak-table-row">
                                <td class="ak-table-column">
                                    {{ line.code }}
                                </td>
                                <td class="ak-table-column">
                                    <a v-if="line.shoppable.href" :href="line.shoppable.href"
                                       class="ak-link ak-link--uppercase" target="_blank" v-html="line.title">

                                    </a>
                                    <span v-else v-html="line.title">

                                        </span>
                                </td>
                                <td class="ak-table-column">
                                    {{ line.quantity }}
                                </td>
                                <td class="ak-table-column">
                                    {{ line.amount.formatted }}
                                </td>
                            </tr>
                            <tr v-for="line in receipt.lines" class="ak-table-row">
                                <td class="ak-table-column"></td>
                                <td class="ak-table-column"></td>
                                <td class="ak-table-column text--right" v-html="line.title"></td>
                                <td class="ak-table-column">{{ line.amount.formatted }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="ak-panel m-b-xs-20  ak-panel--no-padding">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Betaling') }}
                            </div>
                        </div>
                        <table v-if="order.paymentStatus === 1" class="ak-table ak-panel__table">
                            <tbody class="ak-table-body">
                            <tr class="ak-table-row">
                                <td class="ak-table-column">
                                    {{ $t('Betaling ontvangen') }}
                                </td>
                            </tr>
                            <tr v-if="inspector.paymentType" class="ak-table-row">
                                <td class="ak-table-column">
                                    {{ inspector.paymentType.title }}
                                </td>
                            </tr>
                            <tr v-if="order.paymentCardHolderName" class="ak-table-row">
                                <td class="ak-table-column">
                                    {{ order.paymentCardHolderName }}
                                </td>
                            </tr>
                            <tr v-if="order.getPaymentCardNumber" class="ak-table-row">
                                <td class="ak-table-column">
                                    {{ order.getPaymentCardNumber }}
                                    <div v-if="order.paymentCardExpirationMonthAndYear">
                                        {{ $t('Vervaldatum') }} {{ order.paymentCardExpirationMonthAndYear }}
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="order.paymentMessage" class="ak-table-row">
                                <td class="ak-table-column">
                                    {{ order.paymentMessage }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table v-else-if="!order.cancelled" class="ak-table ak-panel__table">
                            <tbody class="ak-table-body">
                            <tr class="ak-table-row">
                                <td class="ak-table-column">
                                    {{ $t('Betaling openstaande') }}
                                </td>
                            </tr>
                            <tr v-if="order.paymentMessage" class="ak-table-row">
                                <td class="ak-table-column">
                                    {{ order.paymentMessage }}
                                </td>
                            </tr>
                            <tr class="ak-table-row">
                                <td class="ak-table-column">
                                    <ak-button @click="openOrderDefinitionForm('payment-form')">
                                        {{ $t('Markeren als betaald') }}
                                    </ak-button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-if="order.comments" class="ak-panel ak-panel--no-padding m-b-xs-20">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Opmerkingen') }}
                            </div>
                        </div>
                        <div class="ak-panel__body">
                            {{ order.comments }}
                        </div>
                    </div>

                    <div class="ak-panel ak-panel--no-padding m-b-xs-20">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Historiek') }}
                            </div>
                        </div>
                        <table class="ak-table ak-panel__table">
                            <thead>
                            <tr class="ak-table-row">
                                <th class="ak-table-head">
                                    {{ $t('Datum') }}
                                </th>
                                <th class="ak-table-head">
                                    {{ $t('Gebeurtenis') }}
                                </th>
                            </tr>
                            </thead>
                            <tbody class="ak-table-body">
                            <tr v-for="history in order.orderHistory"
                                class="ak-table-row">
                                <td class="ak-table-column">{{ history.createdAt | datetime }}</td>
                                <td class="ak-table-column">
                                    <a v-if="history.href" :href="history.href" target="_blank">
                                        {{ history.event }}
                                    </a>
                                    <template v-else>
                                        <span v-html="history.event"></span>
                                    </template>
                                </td>
                            </tr>
                            <tr class="ak-table-row">
                                <td class="ak-table-column">{{ cart.date | datetime }}</td>
                                <td class="ak-table-column">{{ $t('Creatie winkelmandje') }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="ak-panel ak-panel--no-padding  m-b-xs-20">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Interne opmerkingen') }}
                            </div>
                        </div>
                        <div class="ak-panel__body ak-panel__body--small reading">
                            <p v-if="order.internalComments" v-html="order.internalComments">
                            </p>
                            <ak-button @click="openOrderDefinitionForm('internal-comments-form')">
                                {{ $t('Opmerking toevoegen') }}
                            </ak-button>
                        </div>
                    </div>
                    <div class="ak-panel ak-panel--no-padding  m-b-xs-20">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Persoonlijke gegevens') }}
                            </div>
                        </div>
                        <div class="ak-panel__body ak-panel__body--small reading">
                            <p>
                                <strong>{{ cartDetails.firstName }} {{ cartDetails.surname }}</strong><br>
                            </p>
                            <p v-if="cartDetails.streetName || cartDetails.streetNumber || cartDetails.boxNumber || cartDetails.postalCode || cartDetails.city || cartDetails.countryIso">
                                {{ cartDetails.streetName }} {{ cartDetails.streetNumber }}
                                {{ cartDetails.boxNumber }}<br>
                                {{ cartDetails.postalCode }} {{ cartDetails.city }} {{ cartDetails.countryIso }}
                            </p>
                            <p>
                                {{ cartDetails.phone }}<br>
                                <a :href="'mailto:'+cartDetails.email">{{ cartDetails.email }}</a>
                            </p>
                        </div>
                    </div>
                    <div class="ak-panel ak-panel--no-padding  m-b-xs-20">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Factuuradres') }}
                            </div>
                        </div>
                        <div class="ak-panel__body ak-panel__body--small reading">
                            <p>
                                <strong>{{ order.invoiceFirstName }} {{ order.invoiceSurnames }}</strong><br>
                                <strong v-if="order.invoiceCompanyName"> {{ order.invoiceCompanyName }} </strong>
                                <span v-if="order.invoiceVat"> {{ order.invoiceVat }}</span>
                            </p>
                            <p v-if="order.invoiceStreetAddress || order.invoiceStreetNumber || order.invoiceBoxNumber || order.invoicePostalCode || order.invoiceCity || order.invoiceCountryIso">
                                {{ order.invoiceStreetAddress }} {{ order.invoiceStreetNumber }}
                                {{ order.invoiceBoxNumber }}<br>
                                {{ order.invoicePostalCode }} {{ order.invoiceCity }} {{ order.invoiceCountryIso }}
                            </p>
                            <p>
                                {{ order.phone }}<br>
                                <a v-if="order.invoiceEmail" :href="'mailto:' + order.invoiceEmail">
                                    {{ order.invoiceEmail }}
                                </a>
                            </p>
                        </div>
                    </div>
                    <div class="ak-panel ak-panel--no-padding m-b-xs-20">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Leveringsgegevens') }}
                            </div>
                            <strong class="m-l-auto">
                                    <span v-if="order.shippingTypeId === 1">
                                        {{ $t('Afhalen') }}
                                    </span>
                                <span v-else>
                                        {{ $t('Leveren') }}
                                    </span>
                            </strong>
                        </div>
                        <table class="ak-table ak-panel__table">
                            <tbody class="ak-table-body">
                            <tr v-if="order.shippingCompanyName && !order.shippingName && !order.shippingReceiverName">
                                <td class="ak-table-column">
                                    <strong>{{ $t('Bedrijf:') }}</strong>
                                </td>
                                <td class="ak-table-column">
                                    {{ order.shippingCompanyName }}
                                </td>
                            </tr>
                            <tr v-if="order.shippingFirstName" class="ak-table-row">
                                <td class="ak-table-column">
                                    <strong>{{ $t('Voornaam:') }}</strong>
                                </td>
                                <td class="ak-table-column">
                                    {{ order.shippingFirstName }}
                                </td>
                            </tr>
                            <tr v-if="order.shippingSurnames" class="ak-table-row">
                                <td class="ak-table-column">
                                    <strong>{{ $t('Achternaam:') }}</strong>
                                </td>
                                <td class="ak-table-column">
                                    {{ order.shippingSurnames }}
                                </td>
                            </tr>
                            <tr v-if="order.shippingName" class="ak-table-row">
                                <td class="ak-table-column">
                                    <strong>{{ $t('Naam:') }}</strong>
                                </td>
                                <td class="ak-table-column">
                                    {{ order.shippingName }}
                                </td>
                            </tr>
                            <tr v-if="order.shippingReceiverName" class="ak-table-row">
                                <td class="ak-table-column">
                                    <strong>{{ $t('Ontvanger naam:') }}</strong>
                                </td>
                                <td class="ak-table-column">
                                    {{ order.shippingReceiverName }}
                                </td>
                            </tr>
                            <tr v-if="order.shippingEmail" class="ak-table-row">
                                <td class="ak-table-column">
                                    <strong>{{ $t('E-mailadres:') }}</strong>
                                </td>
                                <td class="ak-table-column">
                                    <a :href="'mailto:' + order.shippingEmail">
                                        {{ order.shippingEmail }}
                                    </a>
                                </td>
                            </tr>
                            <tr v-if="order.shippingStreetAddress || order.shippingStreetNumber || order.shippingBoxNumber || order.shippingPostalCode || order.shippingCity || order.shippingCountryIso"
                                class="ak-table-row">
                                <td class="ak-table-column">
                                    <strong>{{ $t('Adres:') }}</strong>
                                </td>
                                <td class="ak-table-column">
                                    {{ order.shippingStreetAddress }} {{ order.shippingStreetNumber }}
                                    {{ order.shippingBoxNumber }},
                                    {{ order.shippingPostalCode }} {{ order.shippingCity }}
                                    {{ order.shippingCountryIso }}
                                </td>
                            </tr>
                            <tr v-if="order.requestedDeliveryDate" class="ak-table-row">
                                <td class="ak-table-column">
                                    <strong>{{ $t('Gewenste leveringsdatum:') }}</strong>
                                </td>
                                <td class="ak-table-column">
                                    {{ $moment(order.requestedDeliveryDate).format('DD-MM-YYYY') }}
                                </td>
                            </tr>
                            <tr v-if="hasDateShippingPlanned" class="ak-table-row">
                                <td class="ak-table-column">
                                    <strong>{{ $t('Geplande leveringsdatum:') }}</strong>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <router-view
                name="childview"
                @paymentSaved="paymentSaved"
        />

        <ak-confirm
                v-model="showConfirm"
                :confirmTitle="$t('Ben je zeker?')"
                :confirmText="$t('Ben je zeker dat je deze bestelling wil annuleren?')"
                :confirmLabel="$t('Ja')"
                :cancelLabel="$t('Nee')"
                @confirm="cancelOrder"
                @cancel="showConfirm = false"
        />
    </div>

</template>

<script>
export default {
    name: 'OrderOverview',
    data() {
        return {
            order: null,
            cart: null,
            inspector: null,
            cartDetails: null,
            receipt: null,
            loading: false,
            showConfirm: false,
            next: null,
            prev: null,
        }
    },
    methods: {
        async getOrder() {
            this.loading = true;
            const result = await this.$get('/order-overview/' + this.$route.params.orderCode);
            this.loading = false;

            if (result.success) {
                this.order = result.data.order;
                this.cart = result.data.cart;
                this.receipt = result.data.receipt;
                this.inspector = result.data.inspector;
                this.cartDetails = result.data.cartDetails;
            }
        },
        backToOrders() {
            this.$router.push({
                name: 'grid',
                params: {bundle: this.$route.params.bundle, definition: this.$route.params.gridDefinition}
            });
        },
        paymentSaved() {
            this.getOrder();
            this.$router.push({name: 'order-overview'});
        },
        openOrderDefinitionForm(definition) {
            this.$router.push({
                name: 'order-overview-form',
                params: {
                    bundle: 'order',
                    orderCode: this.cart.code,
                    definition: definition,
                    objectId: this.order.hashId
                }
            });
        },
        async cancelOrder() {
            this.showConfirm = false;
            this.loading = true;
            const result = await this.$post('/order-cancel/' + this.$route.params.orderCode);
            this.loading = false;

            if (result.success) {
                await this.$router.go();
            }
        },
        getDataFetchParams() {
            const dataFetchParams = JSON.parse(localStorage.getItem('order-grid-fetch-params'));
            const persistentFilters = JSON.parse(this.$cookies.get('order-grid'));

            let allParams = {...dataFetchParams};

            if (persistentFilters && persistentFilters.length !== 0) {
                persistentFilters.forEach((filter, i) => {
                    allParams[`f${i}`] = filter.id;

                    filter.values.forEach((value, key) => {
                        allParams[`v${i}[${key}]`] = value;
                    });
                });
            }

            return allParams;
        },
        async toggleProcessed() {
            await this.$post('/order-processed/' + this.$route.params.orderCode);
        }
    },
    async created() {
        this.getOrder();
        this.loading = false;
    },
}
</script>
